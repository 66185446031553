import { CustomDivider } from '@ui/divider/Divider';
import { ConfigProvider, Flex, Typography } from 'antd';
import { FC, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  title: string;
}

export const OrderFormItem: FC<Props> = ({ title, children }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSizeHeading4: 16,
        },
      }}
    >
      <Flex
        gap={16}
        vertical
        style={{
          paddingTop: 32,
        }}
      >
        <Typography.Title level={4}>{title}</Typography.Title>
        {children}
        <CustomDivider margin={0} />
      </Flex>
    </ConfigProvider>
  );
};
