import React from 'react';

import { ConfigProvider, Typography } from 'antd';

import dayjs from 'dayjs';

import { DealTransactionStatus } from '@components/accounting/transactions/types';
import CustomBadge from '@ui/badge/StatusBadge';

const { Title } = Typography;

interface RenderStatusProps {
  value: string;
}

const RenderTransactionStatus: React.FC<RenderStatusProps> = ({ value }) => {
  const firstChar = value.trim()[0]; // Extract the first non-whitespace character

  if (!isNaN(Number(firstChar))) {
    return (
      <ConfigProvider
        theme={{
          components: {
            Typography: {
              fontSizeHeading4: 14,
              fontWeightStrong: 500,
            },
          },
        }}
      >
        <Title
          level={4}
          style={{
            whiteSpace: 'nowrap',
            position: 'absolute',
            padding: '50px 0',
            translate: '0 -50%',
            zIndex: 2,
          }}
        >
          {dayjs(value).format('YYYY MMMM')}
        </Title>
      </ConfigProvider>
    );
  }

  return (
    <CustomBadge
      text={value}
      type={
        value === DealTransactionStatus.OPEN ||
        value === DealTransactionStatus.RUNNING
          ? 'processing'
          : value === DealTransactionStatus.PAID
          ? 'success'
          : 'warning'
      }
    />
  );
};

export default RenderTransactionStatus;
