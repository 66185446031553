import { AdditionalFieldProps } from '@filters/types';
import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';
import { InventoryAuditProductAutocomplete } from '@ui/form/InventoryAuditProductAutocomplete/InventoryAuditProductAutocomplete';
import { PriceRangeInput } from '@ui/form/PriceRangeInput/PriceRangeInput';
import { QuantityRangeInput } from '@ui/form/QuantityRangeInput/QuantityRangeInput';
import { ScannedRadioInput } from '@ui/form/ScannedRadioInput/ScannedRadioInput';
import { FC } from 'react';

export const InventoryAuditDetailsAdditionalFields: FC<
  AdditionalFieldProps
> = ({ values }) => {
  return (
    <>
      <ExpandableFormItem
        label={'Name'}
        name={'name'}
        defaultOpen={!!values?.name}
      >
        <InventoryAuditProductAutocomplete
          type={'name'}
          placeholder={'Enter name'}
        />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Barcode'}
        name={'barcode'}
        defaultOpen={!!values?.barcode}
        withDivider
      >
        <InventoryAuditProductAutocomplete
          type={'barcode'}
          placeholder={'Enter barcode'}
        />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Vendor'}
        name={'vendor'}
        defaultOpen={!!values?.vendor}
        withDivider
      >
        <InventoryAuditProductAutocomplete
          type={'vendor'}
          placeholder={'Enter vendor'}
        />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Price'}
        name={'price'}
        defaultOpen={!!values?.price}
        withDivider
      >
        <PriceRangeInput />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Inventory QTY'}
        name={'quantity'}
        defaultOpen={!!values?.quantity}
        withDivider
      >
        <QuantityRangeInput fieldKey={'inventoryQty'} queryType={'inventory'} />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Scanned QTY'}
        name={'scannedQty'}
        defaultOpen={!!values?.scannedQty}
        withDivider
      >
        <QuantityRangeInput fieldKey={'scannedQty'} queryType={'inventory'} />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Adjusted'}
        name={'adjusted'}
        defaultOpen={false}
        withDivider
      >
        <ScannedRadioInput options={['none', 'adjusted']} />
      </ExpandableFormItem>
    </>
  );
};
