import { CSSProperties, FC } from 'react';

import { ConfigProvider, Typography } from 'antd';

import { Icon, IconSize } from '@assets/icon';

interface Props {
  text: string;
  textColor?: string;
  isEditable?: boolean;
  onEditChange?: (text: string) => void;
  isTotal?: boolean;
  orderTextStyle?: CSSProperties;
}
export const OrderText: FC<Props> = ({
  text,
  textColor = '#1F2933',
  isEditable = false,
  onEditChange,
  isTotal,
  orderTextStyle = {},
}) => {
  const editableOptions = isEditable
    ? {
        icon: <Icon type={'edit'} size={IconSize.Tiny} />,
        tooltip: 'Click to edit tip',
        onChange: onEditChange,
        enterIcon: null,
      }
    : false;
  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextHeading: textColor,
          fontSizeHeading5: 14,
          fontWeightStrong: isTotal ? 700 : 500,
          lineHeightHeading5: 1.2,
        },
      }}
    >
      <Typography.Title
        editable={editableOptions}
        level={5}
        style={{ margin: 0, ...orderTextStyle }}
      >
        {text}
      </Typography.Title>
    </ConfigProvider>
  );
};
