import { OrderForm } from '@components/selling/order-management/OrderForm';
import { useDrawer } from '@drawer/drawerContext';
import { CustomButton } from '@ui/button/Button';
import useTableElementRoute from '@ui/table/hooks/useTableElementRoute';
import { CSSProperties, FC, ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router';

interface TableElementLinkProps {
  relationUuid?: string | null | undefined;
  eventType: string | undefined;
  relationId?: string;
  children?: ReactNode;
  customStyles?: CSSProperties;
}

export const TableElementLink: FC<TableElementLinkProps> = ({
  relationUuid,
  eventType,
  relationId,
  children,
  customStyles,
}) => {
  const navigate = useNavigate();
  const subPath = useTableElementRoute(eventType);

  const { openDrawer, closeDrawer } = useDrawer();

  const showOrderInfo = useCallback(() => {
    if (eventType === 'sold' || eventType === 'receive') {
      openDrawer(
        <OrderForm
          orderId={relationId as string}
          orderUuid={relationUuid ?? ''}
        />,
        `Order ${relationUuid}`,
        {
          drawerWidth: 720,
        },
      );
    } else {
      navigate(relationUuid ? `${subPath}/${relationUuid}` : `${subPath}`);
      closeDrawer();
    }
  }, [
    eventType,
    openDrawer,
    navigate,
    subPath,
    relationUuid,
    relationId,
    closeDrawer,
  ]);

  return eventType === 'sold' && !relationId ? (
    <></>
  ) : (
    <CustomButton
      type={'link'}
      paddingless
      style={{
        fontSize: 12,
        width: '100%',
        justifyContent: 'left',
        height: 'unset',
        whiteSpace: 'normal',
        textAlign: 'left',
        ...customStyles,
      }}
      onClick={showOrderInfo}
    >
      {children ? children : 'View event'}
    </CustomButton>
  );
};
