import { FC, useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Button, Layout, Menu } from 'antd';

import classNames from 'classnames';

import { useToken } from '@hooks/useToken';
import { useTenantRoutes } from '@router/routes';

import { Logo } from '@ui/logo';

import { useMeQuery } from '@/graphql';
import { useCustomPermissions } from '@hooks/useCustomPermissions';
import { useConfirmationBeforeBackRoute } from '@inventory/inventoryManagement/hooks/useConfirmationBeforeBackRoute';

import SatisfactionModal from '@ui/modal/SatisfactionModal';
import { getMenuItems } from '@ui/navigation/menu/helpers/getMenuItems';
import _ from 'lodash';
import styles from './index.module.scss';

const { Sider } = Layout;

const SideMenu: FC = () => {
  const { sideMenuPermissions } = useCustomPermissions();
  const [collapsed, setCollapsed] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { token } = useToken();
  const { pathname } = useLocation();
  const routes = useTenantRoutes();

  const basePath = pathname.split('/')[2];
  const selectedKey = Object.keys(routes).find((key) => {
    if (!basePath) return false;

    const normalizedBasePath = basePath.includes('-')
      ? _.camelCase(basePath)
      : basePath;
    return normalizedBasePath.includes(key);
  });

  const { data: me } = useMeQuery();

  const handleToggle = () => setCollapsed(!collapsed);

  const { handleCheckPaths } = useConfirmationBeforeBackRoute({});

  const handleRouteChange = useCallback(() => {
    const storedCount = localStorage.getItem('visitCount');

    if (storedCount) {
      const count = +storedCount;
      if (count % 100 === 0) {
        if (me?.me?.attributes?.rate?.data?.attributes?.evaluation) {
          localStorage.setItem('visitCount', (count + 1).toString());
        } else {
          setIsModalOpen(true);
          localStorage.setItem('visitCount', (count + 1).toString());
        }
      } else {
        localStorage.setItem('visitCount', (count + 1).toString());
      }
    } else {
      localStorage.setItem('visitCount', '0');
    }
  }, [me?.me?.attributes?.rate?.data?.attributes?.evaluation]);

  const items = useMemo(
    () =>
      getMenuItems(
        collapsed,
        routes,
        handleRouteChange,
        sideMenuPermissions,
        handleCheckPaths,
        pathname,
      ),
    [
      collapsed,
      routes,
      handleRouteChange,
      sideMenuPermissions,
      handleCheckPaths,
      pathname,
    ],
  );

  return (
    <>
      <Sider
        trigger={null}
        collapsedWidth={104}
        width={260}
        collapsible
        collapsed={collapsed}
        theme={'light'}
        style={{
          minHeight: '100vh',
          borderRight: '1px solid #E7E7E7',
          paddingTop: '18px',
        }}
      >
        <Button
          type={'link'}
          onClick={handleToggle}
          style={{
            height: 'fit-content',
            marginBottom: '50px',
            padding: '0 0 0 32px',
            display: 'flex',
          }}
        >
          <Logo minified={collapsed} />
        </Button>
        <Menu
          theme={'light'}
          mode={'inline'}
          selectedKeys={selectedKey ? [selectedKey] : []}
          className={classNames(styles.menu, {
            [styles.collapsed]: collapsed,
          })}
          style={{
            justifyContent: 'space-between',
            borderInlineEnd: 'none',
            color: token.colorTextSecondary,
          }}
          items={items}
        />
      </Sider>
      <SatisfactionModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};

export { SideMenu };
