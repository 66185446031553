import { FilterFunction } from '@filters/types';
import { transformToDateFilterInput } from '@helpers/filters';
import { CustomerObject } from '@ui/form/ContactsANDCompaniesSelect';
import dayjs from 'dayjs';

export const createEstimateContactFilter: FilterFunction<CustomerObject> = (
  customer,
): EstimateFiltersInput => {
  const contactFilter = customer?.contact
    ? {
        contact: {
          id: {
            eq: customer.contact,
          },
        },
      }
    : {};

  const companyFilter = customer?.company
    ? {
        company: {
          id: {
            eq: customer.company,
          },
        },
      }
    : {};

  return {
    orderId: {
      ...contactFilter,
      ...companyFilter,
    },
  };
};

export const createEstimateIdFilter: FilterFunction<string | undefined> = (
  estimateId,
): EstimateFiltersInput => {
  const estimateIdFilter = estimateId
    ? {
        estimateId: {
          eq: estimateId,
        },
      }
    : {};

  return {
    ...estimateIdFilter,
  };
};

export const createEstimateCreatedAtFilter = (
  createdAt?: [null | dayjs.Dayjs, null | dayjs.Dayjs] | null,
): EstimateFiltersInput => {
  return createdAt
    ? {
        ...(createdAt && {
          createdAt: transformToDateFilterInput(createdAt),
        }),
      }
    : {};
};
