import { ROUTES } from '@router/routes';
import { FC, lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Accounting = lazy<FC>(() => import('@/pages/accounting'));
const AccountDashboard = lazy<FC>(
  () => import('@/pages/accounting/account-dashboard'),
);
const Transactions = lazy<FC>(() => import('@/pages/accounting/transactions'));
const Charts = lazy<FC>(() => import('@pages/accounting/charts'));
const IncomeStatement = lazy<FC>(
  () => import('@/pages/accounting/income-statement'),
);
const Reminders = lazy<FC>(() => import('@/pages/accounting/reminders'));
const Taxes = lazy<FC>(() => import('@/pages/accounting/taxes'));

const SingleTaxes = lazy<FC>(() => import('@/pages/accounting/taxes/index'));
const TaxesCollection = lazy<FC>(
  () => import('@/pages/accounting/taxes/tax-collection'),
);

const {
  index,
  transactions,
  charts,
  incomeStatement,
  reminders,
  taxes: { taxCollection, index: taxesIndex },
} = ROUTES.tenant.accounting;

export const accountingRoutes: RouteObject = {
  Component: Accounting,
  path: index,
  children: [
    {
      Component: AccountDashboard,
      index: true,
    },
    {
      Component: Transactions,
      path: transactions,
    },
    {
      Component: Charts,
      path: charts,
    },
    {
      Component: IncomeStatement,
      path: incomeStatement,
    },
    {
      Component: Reminders,
      path: reminders,
    },
    {
      Component: Taxes,
      path: taxesIndex,
      children: [
        {
          Component: SingleTaxes,
          index: true,
        },
        {
          Component: TaxesCollection,
          path: taxCollection,
        },
      ],
    },
  ],
};
