import React, { useMemo } from 'react';

import AvatarInfo from '@/components/ui/avatar/AvatarInfo';

interface TransactionAvatarInfoProps {
  value: string;
  type?: 'contact' | 'user';
  showImage?: boolean;
  attributes:
    | DealTransactionFragment['attributes']
    | OrderFragment['attributes']
    | ContactsDataByPeriodFragment['attributes']
    | SalesItemReportFragment['attributes'];
}

const RenderContact: React.FC<TransactionAvatarInfoProps> = ({
  value,
  attributes,
  type = 'contact',
  showImage,
}) => {
  const avatarName =
    value ||
    (attributes as OrderFragment['attributes'])?.company?.data?.attributes
      ?.name;

  const avatarSrc = useMemo(() => {
    if (type === 'contact') {
      return (
        (attributes as OrderFragment['attributes'])?.contact?.data?.attributes
          ?.avatar?.data?.attributes?.url ||
        (attributes as OrderFragment['attributes'])?.company?.data?.attributes
          ?.avatar?.data?.attributes?.url ||
        (attributes as ContactsDataByPeriodFragment['attributes'])?.avatar?.data
          ?.attributes?.url
      );
    } else {
      return (attributes as OrderFragment['attributes'])?.sales?.data
        ?.attributes?.avatar?.data?.attributes?.url;
    }
  }, [type, attributes]);
  return (
    avatarName && (
      <AvatarInfo
        alt={avatarName}
        title={avatarName}
        src={avatarSrc}
        showImage={showImage}
      />
    )
  );
};

export default RenderContact;
