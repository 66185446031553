import { useUpdateOrderMutation } from '@/graphql';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';

export const useAddOrderNotes = () => {
  const message = useStatusMessage();
  const [updateOrder, { loading }] = useUpdateOrderMutation({
    onCompleted: async () =>
      message.open('success', 'Note has been added to Order successfully.'),
    onError: (error) =>
      message.open('error', 'Note has not been added to Order successfully.'),
  });

  const handleUpdateOrder = async (
    id: number | string,
    value: string,
    callback?: () => void,
  ) => {
    await updateOrder({
      variables: {
        id: id as string,
        input: {
          note: value,
        },
      },
      onCompleted: callback,
    });
  };
  return {
    handleUpdateOrder,
    loading,
  };
};
