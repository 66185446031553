import { FC, lazy } from 'react';

import { RouteObject } from 'react-router-dom';

import { ROUTES } from '@router/routes';

const EstimatesContract = lazy<FC>(
  () => import('@pages/contracts/estimates/index'),
);
const UpdateInvoiceFrom = lazy<FC>(
  () => import('@inventory/invoices/forms/UpdateInvoiceFrom'),
);
const RequestView = lazy<FC>(
  () => import('@pages/contracts/purchase-requests/RequestView'),
);

const { index, preview } = ROUTES.tenant.contracts.estimates;
export const estimatesRoutes: RouteObject = {
  path: index,
  children: [
    {
      index: true,
      Component: EstimatesContract,
    },
    {
      path: `${preview}/:uuid`,
      Component: RequestView,
    },
    {
      path: ':uuid',
      Component: UpdateInvoiceFrom,
    },
  ],
};
