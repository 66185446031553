import AvatarInfo from '@/components/ui/avatar/AvatarInfo';
import { getTableShopItemData } from '@components/accounting/transactions/hooks/getTableShopItemData';
import { TableElementLink } from '@ui/table/TableElementLink/TableElementLink';
import { FC } from 'react';

interface ShopItemProps {
  attributes:
    | ProductOrderItem
    | CompositeProductOrderItem
    | ServiceOrderItem
    | MembershipOrderItem
    | ClassOrderItem
    | null
    | undefined;
  itemType: string | null | undefined;
}

const RenderShopItem: FC<ShopItemProps> = ({ attributes, itemType }) => {
  const { itemUuid, itemName } = getTableShopItemData(attributes);

  return (
    !!itemName &&
    (itemType ? (
      <TableElementLink eventType={itemType} relationUuid={itemUuid}>
        <AvatarInfo
          isProduct
          showImage={true}
          titleFontSize={12}
          title={itemName}
        />
      </TableElementLink>
    ) : (
      <AvatarInfo
        isProduct
        showImage={false}
        titleFontSize={12}
        title={itemName}
      />
    ))
  );
};

export default RenderShopItem;
