import { SearchSelect } from '@form/inputs/searchSelect/SearchSelect';
import { SelectProps } from 'antd/lib';

import { useProductsLazyQuery } from '@/graphql';
import { FC, useEffect, useState } from 'react';

interface ISearchSKU extends SelectProps {
  onChange?: (value: string) => void;
  initialValue?: string;
  onSearch?: (value: string) => void;
}

export const SKUSearchSelect: FC<ISearchSKU> = ({
  onChange,
  initialValue,
  value,
  onSearch,
  ...props
}) => {
  const [filters, setFilters] = useState({});

  const [fetchProducts, { loading, data }] = useProductsLazyQuery({
    variables: {
      filters: {
        ...filters,
      },
      pagination: {
        limit: 10,
      },
    },
  });

  useEffect(() => {
    fetchProducts();
  }, [filters, fetchProducts]);

  const products = data?.products?.data || [];

  const options = products?.map((product) => ({
    value: product?.attributes?.SKU,
    label: product?.attributes?.SKU,
  }));

  const handleSearch = (value: string) => {
    setFilters({
      SKU: {
        containsi: value,
      },
    });
    onSearch && onSearch(value);
  };
  return (
    <SearchSelect
      defaultValue={initialValue}
      placeholder={'Select the SKU'}
      onChange={onChange}
      options={options}
      loading={loading}
      onSearch={handleSearch}
      value={value}
      {...props}
    />
  );
};
