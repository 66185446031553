import React from 'react';

import AvatarInfo from '@/components/ui/avatar/AvatarInfo';
import { TableElementLink } from '@ui/table/TableElementLink/TableElementLink';

interface TransactionAvatarInfoProps {
  avatarName: string;
  avatarSrc?: string | undefined;
  showImage?: boolean;
  itemType?: string;
  itemUuid?: string;
}

const RenderInventoryItem: React.FC<TransactionAvatarInfoProps> = ({
  avatarName,
  avatarSrc,
  showImage,
  itemType,
  itemUuid,
}) => {
  return (
    <div style={{ fontWeight: 400 }}>
      {itemType ? (
        <TableElementLink eventType={itemType} relationUuid={itemUuid}>
          <AvatarInfo
            isProduct
            titleFontSize={12}
            alt={avatarName}
            title={avatarName}
            src={avatarSrc}
            showImage={showImage}
          />
        </TableElementLink>
      ) : (
        <AvatarInfo
          isProduct
          titleFontSize={12}
          alt={avatarName}
          title={avatarName}
          src={avatarSrc}
          showImage={showImage}
        />
      )}
    </div>
  );
};

export default RenderInventoryItem;
