import { FC, useEffect, useState } from 'react';

import { Flex } from 'antd';
import { SelectProps } from 'antd/lib';

import { useProductsSelectQuery } from '@/graphql';

import { formatToCurrency } from '@helpers/formatter';

import AvatarInfo from '@ui/avatar/AvatarInfo';

import { SearchSelect } from '@form/inputs/searchSelect/SearchSelect';

export const buildProductInfo = (product: ProductSelectFragment) => {
  const availableIn = product?.attributes?.productInventoryItems?.data
    .map((item) => item?.attributes?.businessLocation?.data?.attributes?.name)
    .join(', ');

  return (
    <Flex vertical gap={4}>
      <div>Available in: {availableIn}</div>
      <div>
        Default Price: {formatToCurrency(product?.attributes?.defaultPrice)}
      </div>
    </Flex>
  );
};
interface Props extends SelectProps {
  onChange?: (value: string) => void;
  initValue?: string;
  selectedIds?: Maybe<string>[];
}

export const ProductSelect: FC<Props> = ({
  onChange,
  initValue,
  value,
  selectedIds,
  ...props
}) => {
  const [filters, setFilters] = useState<ProductFiltersInput>({});

  const { data, refetch, loading } = useProductsSelectQuery({
    variables: {
      filters: {
        ...filters,
      },
    },
  });

  useEffect(() => {
    refetch();
  }, [filters, refetch]);

  const products = data?.products?.data || [];

  const productItems = products?.map((product) => ({
    value: product?.id,
    label: (
      <AvatarInfo
        src={product?.attributes?.files?.data?.[0]?.attributes?.url}
        title={product?.attributes?.name}
        description={buildProductInfo(product)}
        isProduct
      />
    ),
    title: product?.attributes?.name,
    disabled: selectedIds?.includes(product?.id),
  }));

  const handleSearch = (value: string) => {
    setFilters({
      or: [
        {
          name: {
            containsi: value,
          },
        },
        {
          SKU: {
            containsi: value,
          },
        },
      ],
    });
  };

  return (
    <SearchSelect
      defaultValue={initValue}
      placeholder={props.placeholder || 'Select the product'}
      onChange={onChange}
      options={productItems}
      loading={loading}
      onSearch={handleSearch}
      value={value}
      optionLabelProp={'title'}
      {...props}
    />
  );
};
