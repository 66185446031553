import { CustomFormItem } from '@form/item/FormItem';
import { Input } from 'antd';

import { FC } from 'react';

interface INoteInput {
  customName: string[];
  label?: string;
  placeholder?: string;
  minRows?: number;
  maxRows?: number;
  maxLength?: number;
  defaultValue?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
}

const { TextArea } = Input;

export const NoteInput: FC<INoteInput> = ({
  customName,
  label = 'Note',
  placeholder = 'Text',
  maxLength = 300,
  maxRows = 10,
  minRows = 3,
  defaultValue,
  onFocus,
  onBlur,
  onChange,
  value,
}) => {
  return (
    <CustomFormItem label={label} name={customName} labelCol={{ span: 24 }}>
      {defaultValue !== undefined && (
        <TextArea
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          maxLength={maxLength}
          autoSize={{ minRows, maxRows }}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          style={{ width: '100%', paddingLeft: 5 }}
        />
      )}
    </CustomFormItem>
  );
};
