import { FC } from 'react';

import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import { OrderIdSelect } from '@components/accounting/transactions/ui/selects/OrderIdSelect';
import { AdditionalFieldProps } from '@filters/types';
import { DateRangeInput } from '@form/inputs/dateRange/DateRangeInput';
import { ContactsANDCompaniesSelect } from '@ui/form';
import { CustomerObject } from '@ui/form/ContactsANDCompaniesSelect';

export const OrdersManagementAdditionalFields: FC<
  AdditionalFieldProps & { entityName?: string }
> = ({ values }) => {
  return (
    <>
      <ExpandableFormItem
        name={'orderId'}
        defaultOpen={!!values?.orderId}
        label={`Order ID`}
      >
        <OrderIdSelect />
      </ExpandableFormItem>

      <ExpandableFormItem
        withDivider
        name={'customer'}
        label={'Customer'}
        defaultOpen={!!values?.customer}
      >
        <ContactsANDCompaniesSelect
          initialValue={values?.customer as CustomerObject}
          allowClear={false}
        />
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        label={'Created At'}
        name={'createdAt'}
        defaultOpen={!!values?.createdAt}
      >
        <DateRangeInput style={{ width: '100%' }} />
      </ExpandableFormItem>
    </>
  );
};
