import { FC } from 'react';

import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import { AdditionalFieldProps } from '@filters/types';

import { DateRangeInput } from '@form/inputs/dateRange/DateRangeInput';
import { ContactsANDCompaniesSelect, EstimateIdSelect } from '@ui/form';
import { CustomerObject } from '@ui/form/ContactsANDCompaniesSelect';

export const EstimatesAdditionalFields: FC<AdditionalFieldProps> = ({
  values,
}) => {
  return (
    <>
      <ExpandableFormItem
        name={'customer'}
        label={'Select Customer/Company'}
        defaultOpen={!!values?.customer}
      >
        <ContactsANDCompaniesSelect
          initialValue={values?.customer as CustomerObject}
          allowClear
        />
      </ExpandableFormItem>
      <ExpandableFormItem
        name={'estimateId'}
        label={'Select Estimate ID'}
        defaultOpen={!!values?.estimateId}
        withDivider
      >
        <EstimateIdSelect defaultValue={values?.estimateId ?? undefined} />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Date Created'}
        name={'createdAt'}
        defaultOpen={!!values?.createdAt}
        withDivider
      >
        <DateRangeInput style={{ width: '100%' }} />
      </ExpandableFormItem>
    </>
  );
};
