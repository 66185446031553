import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';

import { get } from 'lodash';

import { AddNoteBtn } from '@components/selling/components/OrderNote/AddNoteBtn';
import { NoteInput } from '@components/selling/components/OrderNote/NoteInput';
import { UpdOrderBtn } from '@components/selling/components/OrderNote/UpdOrderBtn';
import { Flex } from 'antd';

import { useAddOrderNotes } from '@components/selling/components/OrderNote/hooks/useAddOrderNotes';

interface IOrderNote {
  orderData?: Maybe<OrderFragment>;
}

export const OrderNote: FC<IOrderNote> = ({ orderData }) => {
  const id = `${get(orderData, 'id')}`;
  const defaultNote = get(orderData, 'attributes.note', '');

  const { handleUpdateOrder } = useAddOrderNotes();

  const [note, setNote] = useState<string>();
  const [shouldShowNoteInput, setShouldShowNoteInput] = useState<
    boolean | string
  >();
  const [isShowUpdBtn, setIsShowUpdBtn] = useState<boolean>(false);

  const handleIsShowNoteInput = useCallback(
    () => setShouldShowNoteInput(true),
    [setShouldShowNoteInput],
  );
  const handleToggleUpdBtn = () => setIsShowUpdBtn(!isShowUpdBtn);

  useMemo(() => {
    if (defaultNote) {
      handleIsShowNoteInput();
      setShouldShowNoteInput(defaultNote);
    }
  }, [defaultNote, handleIsShowNoteInput]);

  const handleNoteChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setNote(value);
  };
  const onClickUpd = async () => {
    if (id && note) await handleUpdateOrder(id, note, handleToggleUpdBtn);
  };

  return (
    <>
      <Flex
        justify={'space-between'}
        align={'center'}
        style={{ position: 'relative' }}
      >
        {isShowUpdBtn && (
          <div style={{ position: 'absolute', right: 0 }}>
            <UpdOrderBtn onClick={() => onClickUpd()} />
          </div>
        )}
        {!shouldShowNoteInput && (
          <AddNoteBtn
            onClick={handleIsShowNoteInput}
            label={'Add Order Note'}
          />
        )}
      </Flex>
      {shouldShowNoteInput && (
        <NoteInput
          customName={[id, 'note']}
          label={'Order Note'}
          defaultValue={defaultNote}
          value={note}
          onChange={handleNoteChange}
          onFocus={handleToggleUpdBtn}
        />
      )}
    </>
  );
};
