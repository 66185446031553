import { StarFilled, StarOutlined } from '@ant-design/icons';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { CustomForm } from '@form';

import { CustomFormItem } from '@/components/form/item/FormItem';
import { ConfigProvider, Flex, Form, Modal, Rate } from 'antd';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';

import { useOrdersSettingQuery, useSubmitReviewMutation } from '@/graphql';
import { Loader } from '@components/layout/MainLayout';
import { CustomButton } from '@ui/button/Button';
import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ReviewPage: FC = () => {
  const [form] = useForm();
  const location = useLocation();
  const orderId = location.hash.replace('#', '');
  const { data: orderSetting, loading: loadingOrderSetting } =
    useOrdersSettingQuery();
  const googleReviewLink =
    orderSetting?.ordersSetting?.data[0]?.attributes?.reviewLink ?? '';

  const [showCommentBox, setShowCommentBox] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const message = useStatusMessage();

  const [submitReview] = useSubmitReviewMutation({
    onCompleted: () => {
      message.open('success', 'Review submitted successfully');
    },
    onError: () => {
      message.open('error', 'Failed to submit review');
    },
  });

  const rateForm = Form.useWatch('rating', form);

  const getStarIcon = useMemo(
    () =>
      (count: number): ReactNode => {
        return count < rateForm + 1 ? (
          <StarFilled style={{ color: '#4876D5', fontSize: 38 }} />
        ) : (
          <StarOutlined style={{ color: '#4876D5', fontSize: 38 }} />
        );
      },
    [rateForm],
  );

  const customIcons = useMemo(
    () => [1, 2, 3, 4, 5].map((count) => getStarIcon(count)),
    [getStarIcon],
  );

  const rateCharacter = useCallback(
    ({ index = 0 }) => customIcons[index],
    [customIcons],
  );

  if (loadingOrderSetting) {
    return <Loader spinning={loadingOrderSetting} />;
  }

  const handleOnOk = async () => {
    try {
      await form.validateFields();
      const values = await form.getFieldsValue();
      form.resetFields();

      await submitReview({
        variables: {
          orderId,
          rating: `${values.rating}`,
          body: values.feedback ?? '',
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleRateChange = (value: number) => {
    setShowCommentBox(value < 5);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
    if (googleReviewLink === '') {
      return;
    } else {
      const fullUrl = googleReviewLink.startsWith('http')
        ? googleReviewLink
        : `https://${googleReviewLink}`;
      window.open(fullUrl, '_blank');
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Rate: {
            margin: 10,
          },
        },
      }}
    >
      <div style={{ padding: '40px', maxWidth: '600px', margin: '0 auto' }}>
        <Flex
          align={'center'}
          justify={'center'}
          style={{
            paddingTop: 40,
            fontSize: 24,
          }}
        >
          How would you rate your experience?
        </Flex>
        <CustomForm
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          form={form}
        >
          <CustomFormItem
            name={'rating'}
            style={{ marginTop: 20 }}
            rules={[
              {
                required: true,
                message: 'Please input your Rate',
              },
            ]}
          >
            <Rate character={rateCharacter} onChange={handleRateChange} />
          </CustomFormItem>

          {showCommentBox && (
            <CustomFormItem
              style={{ marginTop: 20, width: '100%' }}
              name={'feedback'}
            >
              <TextArea
                placeholder={'Any Feedback?'}
                style={{ padding: '12px 16px', height: 100 }}
              />
            </CustomFormItem>
          )}
          <CustomButton
            size={'large'}
            type={'primary'}
            style={{ marginTop: 20 }}
            onClick={handleOnOk}
          >
            Send
          </CustomButton>
        </CustomForm>
        <Modal
          title={'Leave a Google Review'}
          open={isModalVisible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          okText={'Yes'}
          cancelText={'No'}
        >
          <p>Would you like to leave a review on Google?</p>
        </Modal>
      </div>
    </ConfigProvider>
  );
};

export default ReviewPage;
