import { FC } from 'react';

import { OrderText } from '@components/selling/pos/orders-list/OrderText';

import { formatToCurrency } from '@helpers/formatter';

import { CustomSpace } from '@ui/space';

interface Props {
  name: string;
  amount: Maybe<number>;
  isEditable?: boolean;
  onEditChange?: (text: string) => void;
  isTotal?: boolean;
  currency?: string;
}

export const OrderSummaryItem: FC<Props> = ({
  name,
  amount,
  isEditable,
  onEditChange,
  isTotal,
  currency,
}) => (
  <CustomSpace block style={{ justifyContent: 'space-between' }}>
    <OrderText
      textColor={!isTotal ? '#959595' : undefined}
      text={name}
      isTotal={isTotal}
    />
    <OrderText
      text={formatToCurrency(amount, currency)}
      isEditable={isEditable}
      onEditChange={onEditChange}
      isTotal={isTotal}
      textColor={isTotal ? '#0146C5' : undefined}
      orderTextStyle={{ maxWidth: '100px' }}
    />
  </CustomSpace>
);
