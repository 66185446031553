import { useMemo } from 'react';

export const getCurrency = (orderType?: Maybe<EnumOrderType>): string => {
  return useMemo(() => {
    if (orderType === 'tradeIn') {
      return 'POINTS';
    }
    return 'USD';
  }, [orderType]);
};
