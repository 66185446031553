import { FC, useEffect } from 'react';

import { useDealTransactionsQuery } from '@/graphql';

import RenderDate from '@/components/accounting/transactions/TransactionTable/TransactionColumns/RenderDate';
import RenderId from '@components/accounting/transactions/TransactionTable/TransactionColumns/RenderId';
import RenderSummaries from '@components/accounting/transactions/TransactionTable/TransactionColumns/RenderSummaries';
import RenderStatus from '@components/accounting/transactions/TransactionTable/TransactionColumns/RenderTransactionStatus';

import { useEntityTableState } from '@hooks/crm/useEntityTableState';

import { CustomTable } from '@ui/table/CustomTable';
import { baseColumnFactory } from '@ui/table/column';

import { useDrawer } from '@drawer/drawerContext';
import { useTenantRoutes } from '@router/routes';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';

export interface Props {
  orderId: string;
}

export const OrderTransactionsInfo: FC<Props> = ({ orderId }) => {
  const { tableParams, handleTableChange, queryParams, handleTotalChange } =
    useEntityTableState<DealTransactionFragment>();
  const { data, loading, refetch } = useDealTransactionsQuery({
    variables: {
      ...queryParams,
      filters: {
        sellingOrder: {
          orderId: {
            eq: orderId,
          },
        },
      },
    },
  });

  const {
    accounting: { transactions },
  } = useTenantRoutes();
  const { closeDrawer } = useDrawer();

  const columns = [
    baseColumnFactory({
      title: 'TRANSACTION ID',
      dataIndex: ['attributes', 'dealTransactionId'],
      render: (value, record) => {
        return (
          <Link to={`${transactions}?id:eq=${record.id}`} onClick={closeDrawer}>
            <RenderId value={value} />
          </Link>
        );
      },
      width: '45%',
    }),
    baseColumnFactory({
      title: 'STATUS',
      dataIndex: ['attributes', 'status'],
      render: (value) => <RenderStatus value={value} />,
    }),
    baseColumnFactory({
      title: 'Total/Paid',
      dataIndex: ['attributes', 'summary'],
      render: (value, { attributes }: DealTransactionFragment) => (
        <RenderSummaries value={value} attributes={attributes} />
      ),
    }),
    baseColumnFactory({
      title: 'DUE DATE',
      dataIndex: ['attributes', 'dueDate'],
      render: (value) => <RenderDate value={value} />,
    }),
  ];

  useEffect(() => {
    refetch();
  }, [queryParams, refetch]);

  useEffect(() => {
    handleTotalChange(data?.dealTransactions?.meta?.pagination.total);
  }, [data?.dealTransactions?.meta?.pagination.total, handleTotalChange]);

  return (
    <CustomTable
      withoutShadow
      bordered={true}
      columns={columns}
      dataSource={data?.dealTransactions?.data}
      loading={loading}
      onChange={handleTableChange}
      pagination={tableParams.pagination}
      style={{ width: '100%' }}
      className={styles.table}
    />
  );
};
