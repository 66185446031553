import { useOrdersQuery } from '@/graphql';
import { useCallback, useMemo } from 'react';

export function useOrderData(id: Maybe<string>, useItemVisibility = false) {
  const { data, loading: orderLoading } = useOrdersQuery({
    variables: {
      filters: {
        id: {
          eq: String(id),
        },
      },
    },
  });

  const order = data?.orders?.data?.[0];

  const orderUuid = order?.attributes?.orderId;

  const orderContact = order?.attributes?.contact?.data as ContactMinFragment;
  const orderCompany = order?.attributes?.company?.data as CompanyMinFragment;
  const orderEmployee = order?.attributes?.sales?.data as UserFragment;

  const orderFiles = order?.attributes?.files?.data;

  const orderCreationDate =
    order?.attributes?.getCreateDate ?? order?.attributes?.createdAt;
  const orderDueDate = order?.attributes?.dueDate;

  const orderBusinessLocation = order?.attributes?.businessLocation
    ?.data as BusinessLocationFragment;

  const orderType = order?.attributes?.type as EnumOrderType;
  const orderNote = order?.attributes?.note;

  const filterByVisibility = useCallback(
    (item: any) => {
      if (useItemVisibility) {
        return item?.attributes?.isVisibleInDocs !== false;
      }
      return true;
    },
    [useItemVisibility],
  );

  const orderProductsBuy = useMemo(
    () =>
      order?.attributes?.products?.data?.filter((item) => {
        return (
          item?.attributes?.purchaseType === 'buy' && filterByVisibility(item)
        );
      }) ?? [],
    [order, filterByVisibility],
  );

  const orderProductsRent = useMemo(
    () =>
      order?.attributes?.products?.data?.filter((item) => {
        return (
          item?.attributes?.purchaseType === 'rent' && filterByVisibility(item)
        );
      }) ?? [],
    [order, filterByVisibility],
  );

  const orderCompositeProducts = useMemo(
    () =>
      order?.attributes?.compositeProducts?.data?.filter((item) => {
        return filterByVisibility(item);
      }) ?? [],
    [order, filterByVisibility],
  );

  const orderClasses = useMemo(
    () =>
      order?.attributes?.classes?.data?.filter((item) => {
        return filterByVisibility(item);
      }) ?? [],
    [order, filterByVisibility],
  );

  const orderServices = useMemo(
    () =>
      order?.attributes?.services?.data?.filter((item) => {
        return filterByVisibility(item);
      }) ?? [],
    [order, filterByVisibility],
  );

  const orderMemberships = useMemo(
    () =>
      order?.attributes?.memberships?.data?.filter((item) => {
        return filterByVisibility(item);
      }) ?? [],
    [order, filterByVisibility],
  );

  const hasOrderItemsBought = useMemo(
    () =>
      !(
        !orderProductsBuy.length &&
        !orderCompositeProducts.length &&
        !orderServices.length &&
        !orderClasses.length &&
        !orderMemberships.length
      ),
    [
      orderProductsBuy.length,
      orderCompositeProducts.length,
      orderServices.length,
      orderClasses.length,
      orderMemberships.length,
    ],
  );

  return {
    order,
    orderUuid,
    orderContact,
    orderCompany,
    orderProductsBuy,
    orderClasses,
    orderProductsRent,
    orderServices,
    orderMemberships,
    orderCompositeProducts,
    orderLoading,
    hasOrderItemsBought,
    orderType,
    orderBusinessLocation,
    orderCreationDate,
    orderDueDate,
    orderEmployee,
    orderFiles,
    orderNote,
  };
}
