import { useMeLazyQuery } from '@/graphql';
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useLocalizationSettings } from '@hooks/settings/useLocalizationSettings';
import { get, replace } from 'lodash';
import dayjs from 'src/dayjsConfig';

interface UserSettingsContextProps {
  timeFormat: EnumLocalizationsettingTimeformat | undefined;
  setTimeFormat: React.Dispatch<
    React.SetStateAction<EnumLocalizationsettingTimeformat | undefined>
  >;
  dateFormat: EnumLocalizationsettingDateformat | undefined;
  setDateFormat: React.Dispatch<
    React.SetStateAction<EnumLocalizationsettingDateformat | undefined>
  >;
  timeZone: string;
  setTimeZone: React.Dispatch<React.SetStateAction<string>>;
  meData: MeQuery | undefined;
  formatDateAndTime: (
    inputDate?: string | Maybe<Date> | number,
    withTime?: boolean,
    customDateFormat?: string | undefined,
  ) => string;
  meLoading: boolean;
}

const UserSettingsContext = createContext<UserSettingsContextProps | undefined>(
  undefined,
);

export const UserSettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [meData, setMeData] = useState<MeQuery | undefined>(undefined);
  const initialTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [fetchMe, { data: fetchedMeData, loading: meLoading }] =
    useMeLazyQuery();

  useEffect(() => {
    fetchMe().catch((error) => {
      console.error('Error fetching user', error?.message);
    });
  }, [fetchMe]);

  const userId = get(meData, 'me.id', '');
  const { userLocalizationInfo } = useLocalizationSettings(userId);

  const initialDateFormat = get(
    userLocalizationInfo,
    'localizationSettings.data[0].attributes.dateFormat',
  );

  const initialEntityTimeZone = get(
    userLocalizationInfo,
    'localizationSettings.data[0].attributes.timeZone',
  );

  const initialEntityTimeFormat = get(
    userLocalizationInfo,
    'localizationSettings.data[0].attributes.timeFormat',
  );

  const [timeFormat, setTimeFormat] = useState<
    EnumLocalizationsettingTimeformat | undefined
  >(initialEntityTimeFormat);
  const [timeZone, setTimeZone] = useState(
    initialEntityTimeZone ?? initialTimeZone,
  );
  const [dateFormat, setDateFormat] = useState<
    EnumLocalizationsettingDateformat | undefined
  >(initialDateFormat);

  useEffect(() => {
    setTimeFormat(initialEntityTimeFormat);
    setTimeZone(initialEntityTimeZone ?? initialTimeZone);
    setDateFormat(initialDateFormat);
    setMeData(fetchedMeData);
  }, [
    initialEntityTimeFormat,
    initialEntityTimeZone,
    initialDateFormat,
    initialTimeZone,
    fetchedMeData,
  ]);

  useEffect(() => {
    dayjs.tz.setDefault(timeZone);
  }, [timeZone]);

  const formatDateAndTime = (
    inputDate?: string | Maybe<Date> | number,
    withTime?: boolean,
    customDateFormat?: string | undefined,
  ): string => {
    const formattedDateFormat = replace(dateFormat ?? 'MM_DD_YYYY', /_/g, '/');
    const formattedTimeFormat = timeFormat === 'HH_mm' ? 'HH:mm' : 'hh:mm A';

    if (!inputDate) {
      return '-';
    }

    const dateTimeFormat = withTime
      ? `${customDateFormat ?? formattedDateFormat} ${formattedTimeFormat}`
      : customDateFormat ?? formattedDateFormat;

    return inputDate === 'nowDate'
      ? dayjs().tz().format(dateTimeFormat)
      : dayjs(inputDate).tz().format(dateTimeFormat);
  };

  return (
    <UserSettingsContext.Provider
      value={{
        dateFormat,
        setDateFormat,
        timeZone,
        setTimeZone,
        timeFormat,
        setTimeFormat,
        meData,
        formatDateAndTime,
        meLoading,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};

export const useUserSettingsContext = (): UserSettingsContextProps => {
  const context = useContext(UserSettingsContext);

  if (!context) {
    throw new Error(
      'useUserSettingsContext must be used within a UserSettingsProvider',
    );
  }

  return context;
};
