export const getTableShopItemData = (
  shopItemData:
    | ProductOrderItem
    | CompositeProductOrderItem
    | ServiceOrderItem
    | MembershipOrderItem
    | ClassOrderItem
    | null
    | undefined,
) => {
  if (!shopItemData)
    return {
      itemUuid: '-',
      itemName: '-',
    };

  let attributes;

  switch (true) {
    case 'product' in shopItemData:
      attributes = (shopItemData as ProductOrderItem)?.product?.data?.attributes
        ?.product?.data?.attributes;
      break;

    case 'compositeProduct' in shopItemData:
      attributes = (shopItemData as CompositeProductOrderItem)?.compositeProduct
        ?.data?.attributes?.compositeProduct?.data?.attributes;
      break;

    case 'service' in shopItemData:
      attributes = (shopItemData as ServiceOrderItem)?.service?.data?.attributes
        ?.serviceLocationInfo?.data?.attributes?.service?.data?.attributes;
      break;

    case 'membership' in shopItemData:
      attributes = (shopItemData as MembershipOrderItem)?.membership?.data
        ?.attributes;
      break;

    case 'class' in shopItemData:
      attributes = (shopItemData as ClassOrderItem)?.class?.data?.attributes
        ?.classLocationInfo?.data?.attributes?.class?.data?.attributes;
      break;

    default:
      attributes = null;
  }

  const itemUuid = attributes?.uuid || '-';
  const itemName = attributes?.name || '-';

  return {
    itemUuid,
    itemName,
  };
};
