import { FC, useEffect, useMemo } from 'react';

import { SelectProps } from 'antd/lib';

import { useOrdersQuery } from '@/graphql';

import { SearchSelect } from '@form/inputs/searchSelect/SearchSelect';

import { useOrderIdDataSelect } from './useOrderIdDataSelect';

interface Props extends SelectProps {
  onChange?: (value: string) => void;
}

export const OrderIdSelect: FC<Props> = ({ onChange, value, ...props }) => {
  const { handleChange, handleSearch, queryParams } = useOrderIdDataSelect({
    onChange,
  });

  const { data, refetch, loading } = useOrdersQuery({
    variables: queryParams,
  });

  useEffect(() => {
    refetch();
  }, [queryParams, refetch]);

  const options = useMemo(() => {
    const ordersData = data?.orders?.data || [];

    const transformedData = ordersData?.map((order) => ({
      value: order?.id,
      label: order?.attributes?.orderId,
    }));
    return transformedData;
  }, [data?.orders?.data]);

  return (
    <SearchSelect
      optionLabelProp={'label'}
      placeholder={'Select the order'}
      onChange={handleChange}
      onSearch={handleSearch}
      options={[...options]}
      value={value}
      loading={loading}
    />
  );
};
