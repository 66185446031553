import { useOrderData } from '@inventory/invoices/hooks/useOrderData';
import { CustomTable } from '@ui/table/CustomTable';
import { FC } from 'react';

import { getCurrency } from '@helpers/getCurrency';
import {
  invoiceTableColumns,
  rentDataColumns,
} from '@inventory/invoices/helpers/static';
import styles from './index.module.scss';

interface Props {
  orderId: string;
}
export const OrderItemsTable: FC<Props> = ({ orderId }) => {
  const {
    orderProductsBuy,
    orderProductsRent,
    orderCompositeProducts,
    orderServices,
    orderClasses,
    orderMemberships,
    orderType,
  } = useOrderData(orderId);

  const hasRent = orderProductsRent.length > 0;

  const currency = getCurrency(orderType);

  return (
    <CustomTable
      withoutShadow
      bordered={true}
      columns={[
        ...invoiceTableColumns('other', false, currency),
        ...(hasRent ? rentDataColumns : []),
      ]}
      dataSource={[
        ...orderProductsBuy,
        ...orderProductsRent,
        ...orderCompositeProducts,
        ...orderServices,
        ...orderClasses,
        ...orderMemberships,
      ]}
      pagination={false}
      style={{ width: '100%' }}
      className={styles.table}
    />
  );
};
