import { FC, useEffect } from 'react';

import { useTasksQuery } from '@/graphql';

import RenderDate from '@/components/accounting/transactions/TransactionTable/TransactionColumns/RenderDate';
import RenderId from '@components/accounting/transactions/TransactionTable/TransactionColumns/RenderId';
import { useDrawer } from '@drawer/drawerContext';
import { PriorityType } from '@helpers/enumTypes';
import { useEntityTableState } from '@hooks/crm/useEntityTableState';
import { useTenantRoutes } from '@router/routes';
import { PrioritySelectOption } from '@ui/form/PrioritySelect/PrioritySelectOption';
import { CustomTable } from '@ui/table/CustomTable';
import { baseColumnFactory } from '@ui/table/column';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';

export interface Props {
  orderId: string;
}

export const OrderTasksInfo: FC<Props> = ({ orderId }) => {
  const { tableParams, handleTableChange, queryParams, handleTotalChange } =
    useEntityTableState<TaskFragment>();

  const { data, loading, refetch } = useTasksQuery({
    variables: {
      ...queryParams,
      filters: {
        order: {
          orderId: {
            eq: orderId,
          },
        },
      },
    },
  });

  const tasksData = data?.tasks?.data || [];
  const { tasks } = useTenantRoutes();
  const { closeDrawer } = useDrawer();

  const columns: ColumnsType<TaskFragment> = [
    baseColumnFactory({
      title: 'TASK',
      dataIndex: ['attributes', 'name'],
      render: (value) => {
        return (
          <Link to={`${tasks}`} onClick={closeDrawer}>
            <RenderId value={value} />
          </Link>
        );
      },
      width: '45%',
    }),

    baseColumnFactory<TaskFragment>({
      title: 'STATUS',
      dataIndex: ['attributes', 'completed'],
      render: (value, { attributes }) => {
        const currentDate = new Date();
        return (
          <RenderId
            value={
              value
                ? 'Completed'
                : attributes?.dueDate && attributes?.dueDate > currentDate
                ? 'Overdue'
                : 'Due'
            }
          />
        );
      },
    }),
    baseColumnFactory({
      title: 'PRIORITY',
      dataIndex: ['attributes', 'priority'],
      render: (value) => {
        return <PrioritySelectOption label={value as PriorityType} />;
      },
    }),
    baseColumnFactory({
      title: 'DUE DATE',
      dataIndex: ['attributes', 'dueDate'],
      render: (value) => <RenderDate value={value} />,
    }),
  ];

  useEffect(() => {
    refetch();
  }, [queryParams, refetch]);

  useEffect(() => {
    handleTotalChange(data?.tasks?.meta?.pagination.total);
  }, [data?.tasks?.meta?.pagination.total, handleTotalChange]);

  return (
    <CustomTable<TaskFragment>
      withoutShadow
      bordered={true}
      columns={columns}
      dataSource={tasksData}
      loading={loading}
      onChange={handleTableChange}
      pagination={tableParams.pagination}
      style={{ width: '100%' }}
      className={styles.table}
    />
  );
};
