import { Icon, IconSize } from '@assets/icon';
import { CustomButton } from '@ui/button/Button';
import { FC } from 'react';

interface IUpdOrderBtn {
  loading?: boolean;
  onClick?: () => void;
}

export const UpdOrderBtn: FC<IUpdOrderBtn> = ({ onClick, loading }) => {
  return (
    <CustomButton
      onClick={onClick}
      type={'link'}
      icon={<Icon type={'check'} size={IconSize.Large} />}
      size={'large'}
      loading={loading}
    />
  );
};
