import { Ref } from '@inventory/invoices/view/InvoiceCard';
import { Card, UploadFile } from 'antd';
import React, { forwardRef } from 'react';
import { ITriggerProps } from 'react-to-print';

interface Props {
  files: UploadFile[];
  handlePrint?:
    | (<T>() => React.ReactElement<
        ITriggerProps<T>,
        string | React.JSXElementConstructor<any>
      >)
    | undefined;
}

export const OrderFilesPrintComponent = forwardRef<Ref, Props>(
  ({ files }, ref) => {
    const imageFiles = files.filter(
      (file) =>
        file.type?.startsWith('image/') ||
        /\.(jpg|jpeg|png|gif|bmp|svg|webp)$/i.test(file.url || ''),
    );

    return (
      <div ref={ref}>
        {imageFiles.map((file) => (
          <Card
            key={file.uid}
            style={{ border: 'none', pageBreakAfter: 'always' }}
          >
            <img src={file.url} alt={file.name} style={{ width: '100%' }} />
          </Card>
        ))}
      </div>
    );
  },
);
