import { Icon } from '@assets/icon';
import { CustomButton } from '@ui/button/Button';

import { FC } from 'react';

interface IAddNoteBtn {
  onClick: () => void;
  label?: string;
}

export const AddNoteBtn: FC<IAddNoteBtn> = ({
  onClick,
  label = 'Add Note',
}) => {
  return (
    <CustomButton
      type={'link'}
      icon={<Icon type={'plus-circle'} />}
      onClick={onClick}
      paddingless
    >
      {label}
    </CustomButton>
  );
};
