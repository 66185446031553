import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';

import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';

const { confirm } = Modal;

export const showConfirm = (link: string, navigate: NavigateFunction) => {
  confirm({
    title:
      'You have unsaved changes on this page. Do you want to leave this page?',
    icon: <ExclamationCircleFilled />,
    okText: 'Yes',
    cancelText: 'No',
    onOk() {
      navigate(link);
    },
    onCancel() {
      return;
    },
  });
};

export const useConfirmationBeforeBackRoute = ({
  entityPlural = '',
}: {
  entityPlural?: string;
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleCheckPaths = (link: string) => {
    if (entityPlural === 'Products' || location.pathname.includes('products')) {
      showConfirm(link, navigate);
      return;
    } else {
      navigate(link);
    }
  };

  return { handleCheckPaths };
};
