import { FC, useMemo, useState } from 'react';

import { Flex, Typography } from 'antd';
import { CollapseProps } from 'antd/lib';

import { useToken } from '@hooks/useToken';

import TextOptions from '@ui/text-options';

import ProfileCollapsed from '@crm/profileCollapsed';
import AvatarInfo from '@ui/avatar/AvatarInfo';
import { CustomSpace } from '@ui/space';
import TextInfoBlock from '@ui/text-info-block';

import { OrderItemsTable } from '@components/selling/order-management/OrderForm/OrderInfoTables/OrderItemsBuyTable';
import { OrderTasksInfo } from '@components/selling/order-management/OrderForm/OrderInfoTables/OrderTasksInfo';
import { OrderTransactionsInfo } from '@components/selling/order-management/OrderForm/OrderInfoTables/OrderTransactionsInfo';
import { useDrawer } from '@drawer/drawerContext';
import { formatToCurrency } from '@helpers/formatter';
import { useTenantRoutes } from '@router/routes';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';

interface Props {
  data: OrderFragment | undefined;
}

const OrderFormInfoCollapsed: FC<Props> = ({ data }) => {
  const { token } = useToken();
  const { closeDrawer } = useDrawer();
  const {
    crm: { contacts, companies },
    accounting: { transactions },
  } = useTenantRoutes();
  const [keys, setKeys] = useState(['1']);

  const handleChange = (keys: string | string[]) =>
    setKeys(Array.isArray(keys) ? keys : [...keys]);

  const contactData = data?.attributes?.contact?.data;
  const companyData = data?.attributes?.company?.data;

  const items: CollapseProps['items'] = useMemo(
    () => [
      {
        key: '1',
        label: <Typography.Title level={4}>Customer</Typography.Title>,
        children: (
          <CustomSpace block direction={'vertical'} size={12}>
            <CustomSpace block style={{ justifyContent: 'space-between' }}>
              <Link
                to={
                  contactData?.id
                    ? `${contacts}/${contactData.attributes?.uuid}`
                    : `${companies}/${companyData?.attributes?.uuid}`
                }
                onClick={closeDrawer}
              >
                <AvatarInfo
                  src={
                    contactData?.attributes?.avatar?.data?.attributes?.url ||
                    companyData?.attributes?.avatar?.data?.attributes?.url
                  }
                  title={
                    contactData?.attributes?.fullName ||
                    companyData?.attributes?.name
                  }
                  description={
                    contactData?.attributes?.email ||
                    contactData?.attributes?.email
                  }
                />
              </Link>
            </CustomSpace>
            <Flex justify={'space-between'} gap={24}>
              <Flex vertical flex={2} gap={10}>
                <CustomSpace block style={{ justifyContent: 'space-between' }}>
                  <TextInfoBlock
                    title={'Phone'}
                    description={
                      contactData?.attributes?.phoneNumber ||
                      companyData?.attributes?.phoneNumber ||
                      '-'
                    }
                    titleFontSize={12}
                  />
                </CustomSpace>
                <TextInfoBlock
                  title={'Address'}
                  description={
                    contactData?.attributes?.address ||
                    companyData?.attributes?.address ||
                    '-'
                  }
                  titleFontSize={12}
                />
              </Flex>
              {contactData?.id && (
                <Flex vertical flex={1} gap={10}>
                  <TextInfoBlock
                    title={'Owes'}
                    description={formatToCurrency(
                      contactData?.attributes?.calculatedOwes,
                    )}
                    titleFontSize={12}
                    link={`${transactions}?contact_id:eq=${contactData?.id}&status:eq=Open`}
                  />
                  <TextInfoBlock
                    title={'Points'}
                    description={contactData?.attributes?.points}
                    titleFontSize={12}
                  />
                </Flex>
              )}
            </Flex>
          </CustomSpace>
        ),
        extra: (
          <TextOptions
            isExpanded={keys.includes('1')}
            color={token.colorBorderSecondary}
          />
        ),
      },
      {
        key: '2',
        label: <Typography.Title level={4}>Order Items</Typography.Title>,
        children: <OrderItemsTable orderId={data?.id as string} />, //TODO: Lesya - Reuse order items table from the invoice
        extra: (
          <TextOptions
            isExpanded={keys.includes('2')}
            color={token.colorBorderSecondary}
          />
        ),
      },
      {
        key: '3',
        label: <Typography.Title level={4}>Transactions</Typography.Title>,
        children: (
          <OrderTransactionsInfo orderId={data?.attributes?.orderId ?? ''} />
        ),
        extra: (
          <TextOptions
            isExpanded={keys.includes('3')}
            color={token.colorBorderSecondary}
          />
        ),
      },
      {
        key: '4',
        label: <Typography.Title level={4}>Tasks</Typography.Title>,
        children: <OrderTasksInfo orderId={data?.attributes?.orderId ?? ''} />,
        extra: (
          <TextOptions
            isExpanded={keys.includes('4')}
            color={token.colorBorderSecondary}
          />
        ),
      },
    ],
    [
      contactData?.id,
      contactData?.attributes?.uuid,
      contactData?.attributes?.avatar?.data?.attributes?.url,
      contactData?.attributes?.fullName,
      contactData?.attributes?.email,
      contactData?.attributes?.phoneNumber,
      contactData?.attributes?.address,
      contactData?.attributes?.calculatedOwes,
      contactData?.attributes?.points,
      contacts,
      companies,
      companyData?.attributes?.uuid,
      companyData?.attributes?.avatar?.data?.attributes?.url,
      companyData?.attributes?.name,
      companyData?.attributes?.phoneNumber,
      companyData?.attributes?.address,
      closeDrawer,
      transactions,
      keys,
      token.colorBorderSecondary,
      data?.id,
      data?.attributes?.orderId,
    ],
  );

  return (
    <ProfileCollapsed
      items={items}
      onChange={handleChange}
      style={{ width: '100%', padding: 0 }}
      className={styles.collapsed}
    />
  );
};

export default OrderFormInfoCollapsed;
