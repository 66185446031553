import { FC, useCallback, useEffect, useState } from 'react';

import { UploadFile } from 'antd';

import { UploadImageList } from '@components/uploadFile/UploadImageList/UploadImageList';

import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';

import { CustomButton } from '@ui/button/Button';
import { CustomSpace } from '@ui/space';

import { CustomForm } from '@form';
import { useCustomForm } from '@form/hooks/useCustomForm';
import { CustomFormItem } from '@form/item/FormItem';

import { useUpdateFiles } from '@inventory/inventoryManagement/hooks/useUpdateFiles';
import ModalWebcam from '@ui/modal/filesModal/ModalWebcam';
import useModalWebcamHook from '@ui/modal/filesModal/useModalWebcamHook';

type FormValues = { files: UploadFile[] };

interface Props {
  orderId: string;
  files?: UploadFile[];
}

export const UploadFilesComponent: FC<Props> = ({ orderId, files }) => {
  const message = useStatusMessage();
  const [form] = useCustomForm<FormValues>();
  const { handleUpdate, isUpdateLoading } = useUpdateFiles({
    ref: 'api::order.order',
  });
  const { isModalOpen, showModal, handleCancel } = useModalWebcamHook();
  const [fileList, setFileList] = useState<UploadFile[]>(files || []);

  const isFileListEmpty = !files?.length && !fileList?.length;

  useEffect(() => {
    form.setFieldValue('files', fileList);
  }, [fileList, form]);

  const handleUploadFile = useCallback(
    async (value: FormValues) => {
      await handleUpdate(value.files, files, orderId).then(() =>
        message.open('success', 'Files uploaded successfully!'),
      );
    },
    [files, handleUpdate, message, orderId],
  );

  const onFilesChange = useCallback((files: UploadFile[]) => {
    setFileList(files);
  }, []);

  const handleRemoveFile = useCallback(
    (file: UploadFile) => {
      const updatedList = fileList.filter((item) => item.uid !== file.uid);
      setFileList(updatedList);
    },
    [fileList],
  );

  const hasFileListChanges =
    form.isFieldsTouched() || files?.length !== fileList.length;

  return (
    <CustomForm<FormValues>
      requiredMark={false}
      onFinish={handleUploadFile}
      form={form}
    >
      <CustomFormItem name={'files'}>
        <CustomSpace size={20} direction={'vertical'}>
          <CustomButton
            onClick={showModal}
            style={{ width: 127 }}
            disabled={fileList.length >= 6}
          >
            Use WebCam
          </CustomButton>
          <UploadImageList
            onRemove={handleRemoveFile}
            onFilesChange={onFilesChange}
            initialValues={fileList || undefined}
            acceptFormat={'*'}
          />
          <CustomButton
            htmlType={'submit'}
            type={'primary'}
            disabled={isFileListEmpty || !hasFileListChanges}
            style={{ width: 127 }}
            loading={isUpdateLoading}
          >
            {'Save Changes'}
          </CustomButton>
          <ModalWebcam
            isModalOpen={isModalOpen}
            setFileList={setFileList}
            handleCancel={handleCancel}
          />
        </CustomSpace>
      </CustomFormItem>
    </CustomForm>
  );
};
