import { ContactDataWithErrors } from '@components/dashboard/dashboardSettings/SettingsModuleFactory/sections/importEntity/contacts/types/types';
import { RefType } from '@helpers/types';
import { TextInfoBlockType } from '@ui/text-info-block';

export type CrmEntityType = 'lead' | 'company' | 'contact';

export type CrmEntityCollectionType = 'leads' | 'companies' | 'contacts';

export type CrmEntityFragments =
  | LeadFragment
  | CompanyFragment
  | ContactFragment;

export type CrmEntityInputMap = {
  lead: LeadInput;
  company: CompanyInput;
  contact: ContactInput;
};

export type CrmEntityMutationMap = {
  lead: CreateLeadMutation;
  company: CreateCompanyMutation;
  contact: CreateContactMutation;
};

export type CrmEntityQueryType =
  | ContactByUuidQuery
  | LeadByUuidQuery
  | CompanyByUuidQuery;

export const CrmEntityToApiEntityMap: Record<CrmEntityType, RefType> = {
  lead: 'api::lead.lead',
  company: 'api::company.company',
  contact: 'api::contact.contact',
};

export enum PathEntityType {
  Contacts = 'contacts',
  Leads = 'leads',
  Companies = 'companies',
}

export interface DrawerFormPropsType {
  onCancel: () => void;
  id: string;
  context?: string;
  onStateUpdate?: React.Dispatch<React.SetStateAction<ContactDataWithErrors[]>>;
}

export interface EntityFormType {
  onClose: () => void;
  primaryAction: string;
}

export type MarketingCampaignContactType = 'leads' | 'contacts';

export type MarketingCampaignContactInputMap = {
  leads: CampaignEnrolledLeadInput;
  contacts: CampaignEnrolledContactInput;
};

export type MarketingCampaignContactMutationMap = {
  leads: CreateCampaignEnrolledLeadMutation;
  contacts: CreateCampaignEnrolledContactMutation;
};

export type DetailsType = {
  info?: TextInfoBlockType[];
  address?: TextInfoBlockType[];
};

export interface AdditionalFieldResult {
  name: string;
}
