import { memoOutDefaultFilters } from '@components/reports/memoOut/static/static';
import { FilterFunction, MemoOutOrderFilterInput } from '@filters/types';
import { transformToDateFilterInput } from '@helpers/filters';

export const createMemoOutReportProductFilter: FilterFunction<string> = (
  productId?: string,
): ProductOrderItemFiltersInput => {
  return productId
    ? {
        product: {
          product: {
            id: {
              eq: productId,
            },
          },
        },
      }
    : {};
};

export const createMemoOutReportOrderFilter: FilterFunction<
  MemoOutOrderFilterInput
> = ({
  contactId,
  companyId,
  sentDate,
  expiryDate,
} = {}): ProductOrderItemFiltersInput => {
  const createdAtFilterBuilder = sentDate
    ? { createdAt: transformToDateFilterInput(sentDate) }
    : {};
  const expiryDateFilterBuilder = expiryDate
    ? { expiryDate: transformToDateFilterInput(expiryDate) }
    : {};

  const contactFilter = contactId
    ? {
        order: {
          ...memoOutDefaultFilters,
          ...createdAtFilterBuilder,
          ...expiryDateFilterBuilder,
          contact: {
            id: {
              eq: contactId,
            },
          },
        },
      }
    : {};

  const companyFilter = companyId
    ? {
        order: {
          ...memoOutDefaultFilters,
          ...createdAtFilterBuilder,
          ...expiryDateFilterBuilder,
          company: {
            id: {
              eq: companyId,
            },
          },
        },
      }
    : {};

  const dateFilter =
    (sentDate || expiryDate) && !contactId && !companyId
      ? {
          order: {
            ...memoOutDefaultFilters,
            ...createdAtFilterBuilder,
            ...expiryDateFilterBuilder,
          },
        }
      : {};

  return {
    or: [contactFilter, companyFilter, dateFilter].filter(
      (filterObject) => Object.keys(filterObject).length > 0,
    ),
  };
};
