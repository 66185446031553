import { FC } from 'react';

import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import { AdditionalFieldProps } from '@filters/types';
import { CompanySelect } from '@ui/form/CompanySelect';
import PurchaseRequestId from '@ui/form/PurchaseRequestIdSelect';

export const PurchaseRequestAdditionalFields: FC<AdditionalFieldProps> = ({
  values,
}) => {
  return (
    <>
      <ExpandableFormItem
        name={'vendor'}
        label={'Select Company'}
        defaultOpen={!!values?.vendor}
      >
        <CompanySelect defaultValue={values?.vendor ?? undefined} />
      </ExpandableFormItem>
      <ExpandableFormItem
        name={'requestId'}
        label={'Select Purchase Request ID'}
        defaultOpen={!!values?.requestId}
        withDivider
      >
        <PurchaseRequestId defaultValue={values?.requestId ?? undefined} />
      </ExpandableFormItem>
    </>
  );
};
