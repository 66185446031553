import RenderContact from '@components/accounting/transactions/TransactionTable/TransactionColumns/RenderContact';
import RenderDate from '@components/accounting/transactions/TransactionTable/TransactionColumns/RenderDate';
import RenderId from '@components/accounting/transactions/TransactionTable/TransactionColumns/RenderId';
import RenderInventoryItem from '@components/accounting/transactions/TransactionTable/TransactionColumns/RenderInventoryItem';
import { TableElementLink } from '@ui/table/TableElementLink/TableElementLink';
import { ColumnsType } from 'antd/es/table';

export const memoOutDefaultFilters = {
  memo: {
    ne: null,
  },
  status: {
    ne: 'draft',
  },
};

export function memoOutStatus(
  data: ProductOrderItemFragment['attributes'],
): string {
  let status = '-';

  if (data) {
    const { order } = data;
    const orderCreationDate = order?.data?.attributes?.createdAt;
    const createdAtDate = orderCreationDate
      ? new Date(orderCreationDate)
      : null;
    const memoDays = order?.data?.attributes?.memo
      ? Number(order?.data?.attributes?.memo)
      : 0;
    const memoExpiryDate = createdAtDate
      ? new Date(createdAtDate.setDate(createdAtDate.getDate() + memoDays))
      : null;
    const amountPaid = order?.data?.attributes?.paidSummary;
    const orderTotal = order?.data?.attributes?.total;

    const isDateExpired = !!(memoExpiryDate && memoExpiryDate < new Date());
    const isExpired = Number(amountPaid) === 0 && isDateExpired;
    const isPartiallyPaid =
      Number(amountPaid) > 0 &&
      Number(amountPaid) < Number(orderTotal) &&
      isDateExpired;
    const isPaid = Number(amountPaid) >= Number(orderTotal) && isDateExpired;

    if (!isDateExpired) {
      status = 'On Sale';
    } else if (isExpired) {
      status = 'Expired';
    } else if (isPartiallyPaid) {
      status = 'Partially Paid';
    } else if (isPaid) {
      status = 'Paid';
    }

    return status;
  } else {
    return status;
  }
}

export const memoOutProductColumns: ColumnsType<ProductOrderItemWithCustomerFragment> =
  [
    {
      key: 'salesOrder',
      title: 'SALES ORDER',
      render: (record) =>
        record?.attributes?.order?.data?.id ? (
          <TableElementLink
            relationId={record?.attributes?.order?.data?.id}
            relationUuid={record?.attributes?.order?.data?.attributes?.orderId}
            eventType={'sold'}
          >
            {record?.attributes?.order?.data?.attributes?.orderId}
          </TableElementLink>
        ) : (
          '-'
        ),
    },
    {
      key: 'productName',
      title: 'PRODUCT NAME',
      dataIndex: [
        'attributes',
        'product',
        'data',
        'attributes',
        'product',
        'data',
        'attributes',
        'name',
      ],
      render: (value, { attributes }) => {
        const data =
          attributes?.product?.data?.attributes?.product?.data?.attributes;

        return (
          <TableElementLink eventType={'products'} relationUuid={data?.uuid}>
            <RenderInventoryItem
              avatarName={value}
              avatarSrc={data?.files?.data?.[0]?.attributes?.url}
            />
          </TableElementLink>
        );
      },
      sorter: true,
    },
    {
      key: 'customer',
      title: 'CUSTOMER',
      dataIndex: [
        'attributes',
        'order',
        'data',
        'attributes',
        'contact',
        'data',
        'attributes',
        'fullName',
      ],
      render: (value, { attributes }: ProductOrderItemWithCustomerFragment) => {
        const customerUuid =
          attributes?.order?.data?.attributes?.contact?.data?.attributes
            ?.uuid ??
          attributes?.order?.data?.attributes?.company?.data?.attributes?.uuid;
        const customerType = attributes?.order?.data?.attributes?.contact?.data
          ?.attributes?.uuid
          ? 'contacts'
          : 'companies';

        return (
          <TableElementLink
            eventType={customerType}
            relationUuid={customerUuid}
          >
            <RenderContact
              value={value}
              attributes={attributes?.order?.data?.attributes}
              showImage={false}
            />
          </TableElementLink>
        );
      },
      sorter: true,
    },
    {
      key: 'sku',
      title: 'SKU',
      dataIndex: [
        'attributes',
        'product',
        'data',
        'attributes',
        'product',
        'data',
        'attributes',
        'SKU',
      ],
      render: (value) => <RenderId value={value || '-'} />,
    },
    {
      key: 'unitPrice',
      title: 'UNIT PRICE',
      dataIndex: ['attributes', 'price'],
      render: (value) => <RenderId value={value ?? 0} />,
      sorter: true,
    },
    {
      key: 'quantityOut',
      title: 'QUANTITY OUT',
      dataIndex: ['attributes'],
      render: (data) => (
        <RenderId value={`${data?.quantity}/${Number(data.quantity)}`} />
      ),
    },
    {
      key: 'sentDate',
      title: 'SENT DATE',
      dataIndex: ['attributes', 'order', 'data', 'attributes', 'createdAt'],
      render: (date) => <RenderDate value={date} />,
      sorter: true,
    },
    {
      key: 'expiryReceived',
      title: 'EXPIRY DATE',
      dataIndex: ['attributes', 'order', 'data', 'attributes', 'expiryDate'],
      render: (date) => <RenderDate value={date} />,
      sorter: true,
    },
    {
      key: 'status',
      title: 'STATUS',
      dataIndex: ['attributes'],
      render: (data) => {
        return <RenderId value={memoOutStatus(data)} />;
      },
    },
  ];
