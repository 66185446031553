import { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const ComponentMask: FC<Props> = ({ children }) => {
  // this component using for hide component inside other component
  return (
    <div style={{ height: 0, width: 0, overflow: 'hidden' }}>{children}</div>
  );
};
