import { useInventoryAuditItemsRangeData } from '@ui/form/QuantityRangeInput/hooks/useInventoryAuditItemsRangeData';

export const useMinMaxValue = (queryType: string, fieldKey: string) => {
  const inventoryData =
    queryType === 'inventory'
      ? useInventoryAuditItemsRangeData({ fieldKey })
      : { min: 0, max: 0 };

  switch (queryType) {
    case 'inventory':
      return { min: inventoryData.min, max: inventoryData.max };
    default:
      return { min: 0, max: 0 };
  }
};
