import { ColumnsType } from 'antd/es/table';

import RenderDate from '@components/accounting/transactions/TransactionTable/TransactionColumns/RenderDate';
import RenderId from '@components/accounting/transactions/TransactionTable/TransactionColumns/RenderId';
import AvatarInfo from '@ui/avatar/AvatarInfo';
import { baseColumnFactory } from '@ui/table/column';
import capitalize from 'lodash/capitalize';

export const returnColumn: ColumnsType<InventoryReturnFragment> = [
  baseColumnFactory({
    title: 'Order',
    dataIndex: ['attributes', 'order', 'data', 'attributes', 'orderId'],
    render: (value) => <RenderId value={value} />,
    sorter: true,
  }),
  baseColumnFactory({
    title: 'Order Type',
    dataIndex: ['attributes', 'order', 'data', 'attributes', 'type'],
    render: (value) => <RenderId value={capitalize(value)} />,
    sorter: true,
  }),
  baseColumnFactory<InventoryReturnFragment>({
    title: 'Customer',
    dataIndex: ['attributes', 'order', 'data'],
    render: (_, entity) => {
      const { company, contact } =
        entity.attributes?.order?.data?.attributes || {};
      const customer = company?.data || contact?.data;

      return (
        <AvatarInfo
          title={
            company?.data
              ? company?.data?.attributes?.name
              : contact?.data?.attributes?.fullName || 'Unnamed'
          }
          src={customer?.attributes?.avatar?.data?.attributes?.url}
        />
      );
    },
    sorter: (a, b) => {
      const { company: aCompany, contact: aContact } =
        a.attributes?.order?.data?.attributes || {};
      const { company: bCompany, contact: bContact } =
        b.attributes?.order?.data?.attributes || {};

      const aCustomerName =
        aCompany?.data?.attributes?.name ||
        aContact?.data?.attributes?.fullName ||
        'Unnamed';
      const bCustomerName =
        bCompany?.data?.attributes?.name ||
        bContact?.data?.attributes?.fullName ||
        'Unnamed';

      return aCustomerName.localeCompare(bCustomerName);
    },
  }),
  baseColumnFactory<InventoryReturnFragment>({
    title: 'Business location',
    dataIndex: ['attributes', 'businessLocation', 'data', 'attributes', 'name'],
    render: (value) => <RenderId value={value ?? ''} />,
    sorter: true,
  }),
  baseColumnFactory<InventoryReturnFragment>({
    title: 'Sublocation',
    dataIndex: ['attributes', 'sublocation', 'data', 'attributes', 'name'],
    render: (value) => <RenderId value={value} />,
    sorter: true,
  }),
  baseColumnFactory({
    title: 'Return Date',
    dataIndex: ['attributes', 'returnDate'],
    render: (date) => <RenderDate value={date} />,
    sorter: true,
  }),
  baseColumnFactory({
    title: 'Return Method',
    dataIndex: ['attributes', 'returnMethod', 'data', 'attributes', 'name'],
    render: (value) => <RenderId value={value} />,
    sorter: true,
  }),
  baseColumnFactory({
    title: 'Return Type',
    dataIndex: ['attributes', 'type'],
    render: (type) => <RenderId value={capitalize(type)} />,
    sorter: true,
  }),
  baseColumnFactory({
    title: 'Reason',
    dataIndex: ['attributes', 'reason'],
    render: (value) => <RenderId value={value} />,
    sorter: true,
  }),
  baseColumnFactory<InventoryReturnFragment>({
    title: 'Employee',
    dataIndex: ['attributes', 'employee', 'data', 'attributes', 'fullName'],
    render: (value) => <RenderId value={value} />,
    sorter: true,
  }),
];

export const returnDrawerData = {
  entityName: 'return',
  entityTitle: 'Return',
  withDefaultFilters: false,
};
