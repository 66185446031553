import { formatToCurrency } from '@helpers/formatter';
import { Typography } from 'antd';
import { FC } from 'react';

interface Props {
  count?: number;
  currency?: string;
}
export const InvoiceTableCell: FC<Props> = ({ count, currency }) => {
  return (
    <Typography.Text
      style={{
        color: '#1F2933',
        fontSize: 12,
        fontWeight: 500,
      }}
    >
      {formatToCurrency(count, currency)}
    </Typography.Text>
  );
};
