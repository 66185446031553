import React, { CSSProperties } from 'react';

import { ConfigProvider, Typography } from 'antd';

interface ConditionalTitleProps {
  value: string | number;
  copyable?: boolean;
  symbolsAmount?: number;
  style?: CSSProperties;
  atr?: any;
}

const RenderId: React.FC<ConditionalTitleProps> = React.memo(
  ({ value, copyable, atr, style, symbolsAmount }) => {
    return value ? (
      <ConfigProvider
        theme={{
          components: {
            Typography: {
              fontSizeHeading5: 12,
              fontWeightStrong: 500,
            },
          },
        }}
      >
        <Typography.Title
          copyable={!!copyable}
          level={5}
          style={style}
          ellipsis={{ tooltip: value }}
        >
          {typeof value === 'string' &&
          symbolsAmount &&
          value.length > symbolsAmount
            ? `${value.slice(0, symbolsAmount)}...`
            : value}
        </Typography.Title>
      </ConfigProvider>
    ) : null;
  },
);

export default RenderId;
