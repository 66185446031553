import { useUserSettingsContext } from '@app/UserSettingsProvider';
import { DatePicker as AntDatePicker, DatePickerProps } from 'antd';
import { replace } from 'lodash';
import { FC } from 'react';

export const DatePicker: FC<DatePickerProps> = ({ ...props }) => {
  const { dateFormat } = useUserSettingsContext();

  const formattedDateFormat = replace(dateFormat ?? 'MM_DD_YYYY', /_/g, '/');

  return <AntDatePicker format={formattedDateFormat} {...props} />;
};
