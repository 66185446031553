import { FC, useCallback } from 'react';

import { Collapse, ConfigProvider } from 'antd';
import { CollapseProps } from 'antd/lib';

import { useToken } from '@hooks/useToken';

import { Icon } from '@assets/icon';

import classNames from 'classnames';
import styles from './index.module.scss';

const ProfileCollapsed: FC<CollapseProps> = ({
  items,
  onChange,
  defaultActiveKey,
  style,
  className,
}) => {
  const { token } = useToken();
  const handleExpandIcon = useCallback(
    (panelProps: any) => (
      <div
        style={{
          transform: `rotate(${panelProps?.isActive ? '-180deg' : '0'})`,
          transition: 'transform 0.3s ease-in-out',
          color: token.colorBorderSecondary,
        }}
      >
        <Icon type={'chevron-down'} size={16} />
      </div>
    ),
    [token.colorBorderSecondary],
  );
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSizeHeading4: 16,
          colorFillAlter: '#fff',
          paddingSM: 0,
          padding: 0,
        },
        components: {
          Collapse: {
            contentPadding: ' 0 0 32px',
            headerPadding: '32px 0',
          },
        },
      }}
    >
      <Collapse
        className={classNames(styles.collapseBlock, className)}
        bordered={false}
        expandIcon={handleExpandIcon}
        defaultActiveKey={defaultActiveKey}
        onChange={onChange}
        expandIconPosition={'end'}
        items={items}
        style={style}
      />
    </ConfigProvider>
  );
};

export default ProfileCollapsed;
