import {
  FC,
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { useSessionStorage } from 'usehooks-ts';

import { useBusinessLocationsLazyQuery } from '@/graphql';
import { StoreContext } from '@components/stores/context/StoreContext';
import { get } from 'lodash';

export const StoreProvider: FC<PropsWithChildren> = memo(({ children }) => {
  const [hasAllStoresOption, setHasAllStoresOption] = useState(true);

  const [selectedStoreId, setSelectedStoreId] = useSessionStorage<
    string | null
  >('selectedStoreId', null);

  const [filters, setFilters] = useState<BusinessLocationFiltersInput>({});

  const [fetch, { data, loading, refetch }] = useBusinessLocationsLazyQuery({
    variables: {
      filters: {
        ...filters,
        archived: { eq: false },
      },
      sort: ['createdAt:asc'],
    },
  });

  const handleSearch = useCallback((value: string) => {
    setFilters({
      or: [
        {
          name: {
            containsi: value,
          },
        },
        {
          location: {
            address: {
              containsi: value,
            },
          },
        },
        {
          phoneNumber: {
            contains: value,
          },
        },
        {
          email: {
            containsi: value,
          },
        },
      ],
    });
  }, []);
  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    refetch({
      filters,
    });
  }, [filters, refetch]);

  const handleChange = useCallback(
    (id: string) => {
      setSelectedStoreId(id === 'all' ? null : id);
    },
    [setSelectedStoreId],
  );

  const handleSetAllStoresOption = useCallback((value: boolean) => {
    setHasAllStoresOption(value);
  }, []);

  const stores = get(
    data,
    'businessLocations.data',
    [] as BusinessLocationFragment[],
  );

  return (
    <StoreContext.Provider
      value={{
        selectedStoreId,
        handleChange,
        handleSearch,
        allStores: stores,
        loading,
        hasAllStoresOption,
        toggleAllStoresOption: handleSetAllStoresOption,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
});
